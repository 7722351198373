




























































import { Component, Vue, Watch } from 'vue-property-decorator'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import IllustrationCreateFaq from '@/app/ui/assets/ill_create_faq.vue'
import Button from '@/app/ui/components/Button/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/ManageCourierController'
import Card from '@/app/ui/components/Card/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import { Route } from 'vue-router'

@Component({
  components: {
    Tabs,
    IllustrationCreateFaq,
    Button,
    Card,
    Loading,
  },
})
export default class ManageCourierPageLayout extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  currentTab = 0
  currentPeriod = ''
  itemTabs = [
    { label: 'Data Manage Courier', name: 'ManageCourierPage', value: 0 },
    {
      label: 'History Upload Bulky',
      name: 'ManageCourierHistoryUploadBulkyPageList',
      value: 1,
    },
  ]
  created(): void {
    this.controller.getCourierLeaveTotalRequest()
    this.setCurrentTab(this.$route)
    if (this.$route.name !== 'ManageCourierPageList') {
      this.$router
        .push({ name: this.getTabRoute(this.currentTab) })
        .catch(() => null)
    }
  }

  private setCurrentTab(route: Route): void {
    const routeName = route.name
    this.currentTab =
      this.itemTabs.filter(tab => tab.name === routeName)[0]?.value || 0
  }

  private onTabsClick(tab: number): void {
    if (tab !== this.currentTab) {
      this.currentTab = tab
      this.itemTabs.forEach(item => {
        if (item.value === this.currentTab) {
          this.$router.push({ name: this.getTabRoute(item.value) })
        }
      })
    }
  }

  private getTabRoute(key: number): string {
    if (key === 0) {
      return 'ManageCourierPageList'
    }
    if (key === 1) {
      return 'ManageCourierHistoryUploadBulkyPageList'
    }
    return ''
  }

  private onExport(): void {
    controller.exportCourierList(controller.exportParams)
  }

  private onDownloadTemplate(): void {
    controller.downloadTemplate()
  }

  @Watch('$route')
  onRouteChange(route: Route): void {
    this.setCurrentTab(route)
  }
}
